.authRedirectContainer {
    margin: 20px;
    text-align: center;
    text-decoration: none !important;
    color: #444;
    font-size: 14px;
}

.logo-container {
    margin: -28px -28px 20px;
    text-align: center;
    padding: 50px;
    box-sizing: border-box;
    background: var(--main-color);
    background: linear-gradient(to bottom, #0090f3cf, #0090f396, #0090f357, #78ffd600);

    img {
        width: 120px;
    }
}

.googleButton {
    width: 100%;
    border: 0;
    padding: 21px;
    border-radius: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: 0.2s;
    background-color: #fff;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    img {
        width: 20px;
        margin-right: 10px;
    }
}

.container{
    max-width: 400px;
    margin: auto;
    padding: 20px;

    .imageContainer{
        text-align: center;
        margin-bottom: 30px;

        img{
            height: 200px;
        }
    }
}

.title{
    color: var(--color-text)
}

.backButton{
    text-align: center;
    margin-top: 10px;
    font-size: 12px;
}
