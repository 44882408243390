.participants-selector-container {
    p {
        font-size: 12px;
        color: var(--text-color);
        margin-bottom: 5px;
    }

    .participants-container {
        margin-bottom: 20px;

        .participant-container {
            display: inline-flex;
            background-color: var(--secondary-bkg-color);
            padding: 6px 10px;
            margin-right: 7px;
            border-radius: 5px;
            transition: 0.2s;
            margin-bottom: 10px;
            cursor: pointer;

            .imageContainer {
                width: 20px;
                height: 20px;
                border-radius: 100%;
                overflow: hidden;
                margin-right: 5px;
                filter: grayscale(1);

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            &:hover {
                background-color: var(--secondary-bkg-color);
            }

            &.selected {
                background-color: var(--main-color);
                color: #fff;
                box-shadow: 0px 1px 5px #4daef1;

                .imageContainer{
                    filter: none;
                }

                &:hover {
                    background-color: #40b3ff;
                }
            }
        }
    }
}
