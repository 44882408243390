.actionItem{
    font-weight: 500;
    display: flex;

    &.red{
        color: #e12748;
    }

    .icon{
        margin-right: 10px;
    }
}