.mockupCard {
    height: 200px;
    background-color: var(--secondary-bkg-color);
    border-radius: 20px;
    position: relative;

    .favoriteIcon{
        background-color: var(--bkg-color);
        height: 30px;
        width: 30px;
        border-radius: 100%;
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .title{
        background-color: var(--bkg-color);
        height: 30px;
        width: 60%;
        border-radius: 5px;
        position: absolute;
        left: 10px;
        bottom: 30px;
    }

    .date{
        background-color: var(--bkg-color);
        height: 15px;
        width: 50px;
        border-radius: 5px;
        position: absolute;
        left: 10px;
        bottom: 10px;
    }
}