.loader-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--loader-bkg-color);
    text-align: center;
    z-index: 9999;
}

.loader-gif {
    width: 250px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.shapes-5 {
    width: 40px;
    height: 40px;
    color: var(--secondary-text-color);
    background: linear-gradient(currentColor 0 0), linear-gradient(currentColor 0 0), linear-gradient(currentColor 0 0),
        linear-gradient(currentColor 0 0);
    background-size: 21px 21px;
    background-repeat: no-repeat;
    animation: sh5 1.5s infinite cubic-bezier(0.3, 1, 0, 1);

    width: 80px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes sh5 {
    0% {
        background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    }
    33% {
        background-position: 0 0, 100% 0, 100% 100%, 0 100%;
        width: 60px;
        height: 60px;
    }
    66% {
        background-position: 100% 0, 100% 100%, 0 100%, 0 0;
        width: 60px;
        height: 60px;
    }
    100% {
        background-position: 100% 0, 100% 100%, 0 100%, 0 0;
    }
}
