.addParticipantsSelectorContainer {
    position: relative;

    .optionsContainer {
        position: absolute;
        background-color: var(--bkg-color);
        width: 100%;
        box-shadow: 0px 2px 13px var(--shadow);
        border-radius: 10px;
        max-height: 200px;
        overflow: scroll;

        .optionItem {
            padding: 10px;
            cursor: pointer;
            display: flex;
            gap: 10px;
            align-items: center;

            span {
                small {
                    color: var(--secondary-text-color);
                }
            }

            &:hover {
                background-color: var(--secondary-bkg-color);
            }
        }

        .noResultsMessage {
            padding: 10px;
            color: var(--text-color);
            font-style: italic;
            font-size: 14px;
        }
    }

    .selectedParticipantInfo {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-bottom: 10px;

        .participant {
            display: flex;
            gap: 10px;
            flex: 1;
            align-items: center;

            .participantUsername {
                font-size: 20px;
                display: block;
                font-weight: 500;
                color: var(--text-color);
            }

            .participantName {
                color: var(--secondary-text-color);
            }
        }

        .participantRemove {
            float: right;
            font-size: 11px;
            color: #e54e4e;
        }
    }
}