.sub-menu-container {
    display: flex;
    margin-bottom: 15px;
    padding: 0 5px;

    .sub-menu-item {
        flex: 1;

        .sub-menu-item-text {
            color: var(--text-color);
            font-weight: 500;
            padding: 2px 0px;
            width: fit-content;
            cursor: pointer;

            &.selected {
                border-bottom: 2px solid var(--text-color);
            }
        }
    }
}