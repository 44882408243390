.participantsListSelector {
    margin-bottom: 15px;

    .topRow {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        font-weight: 600;
        margin-bottom: 10px;
        color: var(--secondary-text-color);
    }

    .participant {
        margin-bottom: 5px;
        display: flex;
        place-content: space-between;

        &.inactive {
            opacity: 0.5;
            pointer-events: none;
        }

        .participantUsername {
            font-size: 16px;
            font-weight: 500;
            color: var(--text-color);
        }

        .participantName {
            font-size: 14px;
            color: var(--secondary-text-color);
        }

        .neutralBalance {
            font-size: 16px;
            color: #666;
        }
        
        .positiveBalance {
            color: #6c9d09;
        }
        
        .negativeBalance {
            color: #ff6060;
        }

        .total{
            font-size: 12px;
            color: var(--secondary-text-color);
        }
    }

    // .participantInfo {
    //     margin-top: 10px;
    //     overflow: auto;

    //     .participantUsername {
    //         display: flex;
    //         font-weight: 500;
    //         color: var(--text-color);
    //         align-items: center;

    //         small {
    //             font-weight: normal;
    //             border: 1px solid #afafaf;
    //             padding: 0 5px;
    //             border-radius: 5px;
    //             color: var(--text-color);
    //             font-size: 10px;
    //             margin-left: 5px;
    //         }
    //     }

    //     .totalExpense,
    //     .positiveBalance,
    //     .negativeBalance,
    //     .neutralBalance {
    //         font-size: 12px;
    //         color: var(--text-color);
    //     }

    //     .positiveBalance {
    //         color: #6c9d09;
    //     }

    //     .negativeBalance {
    //         color: #ff6060;
    //     }

    //     .separator {
    //         font-size: 12px;
    //         margin: 10px;
    //         color: #888;
    //     }
    // }

    // .buttonsContainer {
    //     display: inline-block;
    //     text-align: right;

    //     .listActionButton {
    //         background-color: #0090f30d;
    //         color: var(--main-color);
    //         width: -moz-fit-content;
    //         width: fit-content;
    //         font-size: 10px;
    //         display: inline-block;
    //         margin: 3px;
    //         border-radius: 6px;
    //         max-width: 60px;
    //         text-align: center;
    //         box-sizing: border-box;
    //         font-weight: 500;
    //         padding: 12px 10px;
    //         cursor: pointer;
    //     }

    //     .addPaymentButton {
    //         background-color: var(--main-color);
    //         color: #fff;
    //     }
    // }
}