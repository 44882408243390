.roomItem {
    border-radius: 20px;
    overflow: hidden;
    height: 200px;
    position: relative;
    background-color: var(--skeleton-bkg-color);
    cursor: pointer;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .archivedField {
        background-color: #6b6b6b99;
        color: #ffffff;
        font-size: 10px;
        padding: 5px 10px;
        border-radius: 5px;
        display: block;
        text-align: center;
        font-weight: 300;
        backdrop-filter: blur(3px);
    }

    .favoriteField{
        top: 10px;
        right: 10px;
        position: absolute;
        background-color: #ffffff8f;
        border-radius: 10px;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #666;

        &.isFavorite{
            color: #e55266;
        }
    }

    .titleContainer {
        color: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #000;
        background: linear-gradient(0deg, #0000007a, #0000002b 68%, #ffffff00 96%);
        box-sizing: border-box;
        padding: 20px 15px 10px 15px;

        .title {
            display: block;
            font-weight: bold;
        }

        .date {
            font-size: 12px;
            display: inline-flex;
            align-items: center;
            gap: 3px;
        }

        .balance{
            color: #ff7e7e;
            font-size: 12px;
            margin-right: 5px;
            text-shadow: 0px 1px 3px black;

            &.positive{
                color: #6bff9d;
            }
        }

        .participants {
            position: absolute;
            right: 14px;
            bottom: 10px;
            display: flex;

            &.omitedParticipants {
                right: 10px;
                bottom: 9px;
            }

            .partcipantImg {
                display: inline-block;
                margin-left: -10px;
                width: 20px;
                height: 20px;

                img {
                    border-radius: 100%;
                    border: 2px solid #f2f2f2;
                    display: inline-block;
                    background-color: var(--bkg-color);
                    box-shadow: 0px 2px 6px #3333336b;
                }
            }
        }

        .participantsOmited {
            display: inline-block;
            background-color: #d5d5d5;
            color: #666;
            border-radius: 14px;
            font-size: 10px;
            font-weight: bold;
            border: 2px solid #fff;
            margin-left: -10px;
            box-shadow: 0px 2px 6px #3333336b;

            span {
                padding: 2px 7px 1px 3px;
                display: block;
            }
        }
    }
}
