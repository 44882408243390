nav {
    color: var(--text-color);
    margin-bottom: 10px;
    position: fixed;
    z-index: 1;
    background-color: var(--nav-color);
    width: 100%;
    top: 0;
    left: 0;
    padding: 5px;
    backdrop-filter: blur(2px);

    .navSubcontainer {
        max-width: 1034px;
        margin: auto;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 20px;
        height: 45px;

        h1 {
            font-style: italic;
            margin: 0;
            margin: -8px;
            width: fit-content;
        }
    }
}

//////////////

.menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    min-width: 210px;
    font-size: 14px;
    padding: 14px;
    right: 0;
    top: 40px;
    background-color: var(--card-bkg);
    border-radius: 12px;
    box-shadow: 0px 3px 5px rgba(51, 51, 51, 0.15);
    z-index: 2;

    .userInfo {
        display: flex;
        gap: 10px;
        align-items: center;
        color: var(--text-color);
    }

    hr {
        margin: 10px -12px;
        background-color: var(--secondary-bkg-color);
        ;
    }

    .items {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .item {
            font-weight: 500;
            display: flex;
            gap: 6px;
            align-items: center;
            color: var(--secondary-text-color);

            &.active {
                font-weight: 600;
                color: var(--text-color);
            }
        }
    }
}

.burgerMenu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20px;
    height: 15px;
    cursor: pointer;

    .line {
        height: 3px;
        background-color: var(--secondary-text-color);
        border-radius: 2px;
        transition: all 0.2s ease;
    }

    &.active{

        .line1{
            transform: rotate(45deg) translate(5px, 4px);
        }

        .line2{
            opacity: 0;
        }

        .line3{
            transform: rotate(-45deg) translate(4px, -3px);
        }
    }
}