.container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .userContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        .user {
            display: flex;
            align-items: center;
            gap: 10px;

            // img {
            //     width: 30px;
            //     border-radius: 100%;
            // }

            small {
                font-weight: normal;
                border: 1px solid #afafaf;
                padding: 0 5px;
                border-radius: 5px;
                color: var(--text-color);
                font-size: 10px;
            }
        }
    }
}