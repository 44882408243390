// @import url("./_theme");

* {
    // font-family: 'Dongle', sans-serif;
    // font-family: sans-serif;
    font-family: "Be Vietnam Pro", sans-serif;
    // font-family: 'Raleway', sans-serif;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    padding-bottom: 20px;
    background-color: var(--bkg-color);
    // font-family: 'Dongle', sans-serif;
}

a {
    text-decoration: none !important;
    color: #444;
    font-family: sans-serif;
}

hr {
    background-color: #ccc;
    border: 0;
    height: 1px;
    margin: 10px 0;
}

.app-container {
    padding: 10px;
    max-width: 1024px;
    min-height: 88vh;
    margin: auto;
}

// h3.title {
//     margin: 0;
//     margin-bottom: 8px;
//     // color: #444;
// }

.animate__animated {
    --animate-duration: 0.2s;
}

// .mockup-card {
//     height: 200px;
//     background-color: #ccc;
//     border-radius: 20px;
// }

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.no-click {
    pointer-events: none;
}

.popup-content {
    path{
        color: var(--bkg-color);
    }
}
