.currencyInput {
    width: 100%;
    box-sizing: border-box;
    border: none;
    font-size: 30px;
    text-align: center;
    padding: 10px;
    padding-bottom: 0;
    color: var(--text-color);
    background-color: var(--bkg-color);
    font-weight: 500;
}
