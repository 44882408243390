.errorMsg {
    font-size: 11px;
    position: absolute;
    top: 3px;
    right: 0;
    color: #e93636;
}

.coverpagesSelector {
    scroll-snap-type: x mandatory;
    overflow-x: auto;
    display: flex;
    margin-bottom: 10px;

    .child {
        scroll-snap-align: start;
        width: 150px;
        margin-left: 5px;
        height: 160px;

        .upload-img-container {
            width: 150px;
            height: 150px;

            .upload-input {
                label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 150px;
                    flex-direction: column;
                    cursor: pointer;
                    color: var(--text-color);
                    background-color: var(--bkg-color);
                    border: 2px dashed #888;
                    border-radius: 15px;
                    box-sizing: border-box;
                    transition: 0.2s;

                    .uploadIcon {
                        display: block;
                        margin-bottom: 5px;
                        color: var(--secondary-text-color);
                    }

                    span {
                        font-size: 12px;
                        color: var(--secondary-text-color);
                        font-weight: 500;
                    }

                    &:hover {
                        background-color: var(--secondary-bkg-color);
                    }
                }
            }

            .upload-preview {
                position: relative;
                border-radius: 15px;
                overflow: hidden;
                height: 150px;
                box-sizing: border-box;
                border: 4px solid transparent;

                img {
                    object-fit: cover;
                    width: 150px;
                    height: 150px;
                    background-color: var(--secondary-bkg-color);
                }

                span {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    background-color: #333;
                    border: 2px solid #fff;
                    padding: 0 3px;
                    border-radius: 100%;
                    color: var(--text-color);
                    cursor: pointer;
                    box-shadow: 0px 2px 5px #33333369;
                }

                &.selected-img {
                    border: 4px solid var(--main-color);
                }
            }
        }

        .child-img-container {
            overflow: hidden;
            border-radius: 20px;
            height: 150px;
            border: 4px solid transparent;
            cursor: pointer;

            img {
                object-fit: cover;
                width: 150px;
                height: 100%;
                background-color: var(--secondary-bkg-color);
            }

            &.selected-img {
                border: 4px solid var(--main-color);
            }
        }
    }
}
