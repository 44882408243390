.roomList{
    .roomsContainer{
        display: grid;
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(1,1fr);
        overflow-y: hidden;
        margin-bottom: 10px;
    }
}

@media (min-width: 600px) {
    .roomList{
        .roomsContainer{
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}