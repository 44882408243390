.img-container{
    border-radius: 20px;
    overflow: hidden;
    height: 400px;
    position: relative;
    box-shadow: 0px 8px 9px #33333326;
    background-color: #ccc;

    img{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .archived-field{
        position: absolute;
        top: calc(50% - 30px);
        left: 0;
        background-color: #6b6b6b99;
        width: 100%;
        text-align: center;
        color: #fff;
        padding: 20px;
        box-sizing: border-box;
        backdrop-filter: blur(3px);
    }

    .title-container{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 30px;
        background: rgb(0,0,0);
        background: linear-gradient(0deg, #00000083 0%, #00000045 68%, #ffffff00 96%);
        box-sizing: border-box;
        
        .title{
            font-size: 22px;
            font-weight: bold;
            color: #fff;
            display: block;
        }

        .subtitle{
            color: #fff;
            display: flex;
            gap: 5px;
        }
    }

    .action-button{
        position: absolute;
        top: 25px;
        background-color: #fff;
        color: #666;
        border-radius: 100%;
        cursor: pointer;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.back-button{
            left: 25px;
            font-size: 20px;
        }

        &.edit-button{
            right: 25px;
            font-size: 24px;
        }
    }

    .options{
        position: absolute;
        z-index: 1;
        background-color: #fff;
        top: 80px;
        right: 25px;
        border-radius: 8px;
        overflow: hidden;
        
        .option{
            padding: 10px;
            cursor: pointer;
            color: #333;
            transition: 0.2s;

            &:hover{
                color: #000;
                background-color: #f2f2f2;
            }
        }
    }
}

.description{
    color: #666;
    display: block;
    font-size: 13px;
    margin-bottom: 15px;
}

.expense-options{
    background-color: #fff;
    right: 40px;
    border-radius: 8px;
    z-index: 1;
    overflow: hidden;

    .option{
        font-size: 14px;
        cursor: pointer;
        padding: 10px;

        &:hover{
            background-color: #f2f2f2;
        }
    }
}