.expenses-list-container {

    .title{
        font-weight: 600;
        margin-bottom: 5px;
        color: var(--secondary-text-color);
    }

    .list{
        display: flex;
        flex-direction: column;
        gap: 10px;

        .expense{
            box-shadow: 0px 2px 5px rgb(0 0 0 / 5%);
            background-color: var(--card-bkg);
            border-radius: 10px;
            padding: 12px;
        
            hr{
                margin: 10px -12px;
                background-color: var(--secondary-bkg-color);//#f0f0f0;
            }

            .price{
                color: var(--text-color);
            }

            .description{
                color: var(--secondary-text-color);
            }
        }
    }

    // .title {
    //     margin: 0;
    //     margin-bottom: 8px;
    //     color: var(--text-color);
    // }

    .expenseContainer {
        position: relative;
    }

    .expense-item {
        padding: 9px;
        border-radius: 8px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 0.2fr;

        .value-container {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: clip;
            display: flex;

            .color-dot {
                width: 6px;
                height: 100%;
                display: inline-block;
                margin-right: 10px;
                margin-bottom: -2px;
                border-radius: 4px;
            }
        }
    }

    .value {
        display: inline-block;
        font-weight: 500;
        color: var(--text-color);
        margin-right: 20px;
        font-size: 16px;

        .owner {
            font-weight: 100;
            font-size: 10px;
            font-style: italic;
            display: flex;
            align-items: center;
            margin-left: 5px;
            gap: 4px;
        }
    }

    .info {
        margin-top: 3px;
        font-size: 12px;
        color: var(--text-color);

        .description {
            display: block;
        }

        .paymentDescription {
            color: #6c9d09;
            font-style: italic;
        }

        .participants {
            display: flex;
            height: 20px;
            margin-left: 5px;

            .participantsOmited {
                display: flex;
                align-items: center;
                background-color: #d5d5d5;
                color: #666;
                border-radius: 14px;
                font-size: 8px;
                height: 16px;
                font-weight: bold;
                border: 1px solid #fff;
                margin-left: -5px;

                span {
                    padding: 0 4px 0px 2px;
                }
            }
        }
    }

    .date {
        display: block;
        margin-top: 5px;
        font-size: 12px;
        color: var(--text-color);
        text-align: end;
    }

    .actions {
        font-weight: 200;
        font-size: 12px;
        text-align: right;
        margin-top: 5px;
        color: var(--text-color);
        cursor: pointer;
    }
}

.expense-options {
    background-color: var(--bkg-color);
    box-shadow: 0px 3px 7px var(--shadow);
    right: 40px;
    border-radius: 8px;
    z-index: 1;
    overflow: hidden;

    .option {
        font-size: 14px;
        cursor: pointer;
        padding: 10px;
        color: var(--text-color);

        &:hover {
            background-color: var(--bkg-color);
        }
    }
}

.expense-modal-detail {
    .expense-info {
        text-align: center;
        margin-bottom: 10px;

        h3 {
            margin-bottom: 0;
            font-size: 24px;
            color: var(--text-color) !important;
        }

        small {
            font-size: 18px;
            color: var(--text-color);
        }

        p {
            margin: 2px;
            color: var(--text-color);
            font-size: 12px;
            color: var(--text-color);

            &.description {
                font-size: 14px;
                color: var(--text-color);
            }
        }
    }

    .expense-participants {
        h5 {
            margin: 0;
            margin-bottom: 10px;
            color: var(--text-color);
            font-size: 16px;
        }

        .expense-participant-info {
            margin-bottom: 5px;
            display: grid;
            grid-template-columns: 1fr 1fr;

            .box {
                display: flex;
                align-items: center;

                img {
                    width: 25px;
                    height: 25px;
                    border-radius: 100%;
                    border: 2px solid #fff;
                    box-shadow: 0px 2px 5px #33333357;
                    margin-right: 10px;
                }

                span {
                    color: var(--text-color);
                }
            }

            .expense-participant-value {
                text-align: right;
                color: #4caf50;
                margin-top: 6px;
            }
        }
    }
}

.participants {
    display: flex;
    height: 20px;
    margin-left: 5px;

    .participantsOmited {
        display: flex;
        align-items: center;
        background-color: #d5d5d5;
        color: #666;
        border-radius: 14px;
        font-size: 8px;
        height: 16px;
        font-weight: bold;
        border: 1px solid #fff;
        margin-left: -5px;

        span {
            padding: 0 4px 0px 2px;
        }
    }
}

.userImg {
    margin-left: -5px;
    // width: 15px;
    // height: 15px;

    // img {
    //     border-radius: 100%;
    //     border: 1px solid #f2f2f2;
    //     background-color: var(--bkg-color);

    //     width: 100%;
    //     height: 100%;
    // }
}