.container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: 0 20px 10px;

    .addRoomCard {
        background-color: var(--nav-color);
        border-radius: 10px;
        box-sizing: border-box;
        cursor: pointer;
        transition: 0.2s;
        padding: 19px;
        border: 1px solid var(--bkg-color);
        display: flex;
        justify-content: center;
        backdrop-filter: blur(3px);

        span{
            font-weight: 500;
            color: var(--text-color);
        }
    }
}