.container {
    .infoCard {
        display: flex;
        align-items: center;

        .avatarContainer {
            width: 100px;
            height: 100px;
            border-radius: 100%;
            overflow: hidden;
            margin-right: 20px;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .infoContainer{
            h4{
                margin: 0;
                padding-bottom: 8px;
                font-size: 22px;
                color: var(--text-color);
            }

            span{
                font-size: 14px;
                color: var(--text-color);
            }
        }
    }
}
