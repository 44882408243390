body {
    --text-color: #222;
    --secondary-text-color: #444;
    --bkg-color: #f8f8f8;
    --secondary-bkg-color: #e9e9e9;
    --loader-bkg-color: #ffffff9e;
    --skeleton-bkg-color: #ccc;
    --nav-color: rgba(255, 255, 255, 0.795);
    --shadow: #33333352;
    --main-color: #0090f3;
    --card-bkg: #fff;
}
body.dark-theme {
    --text-color: #eee;
    --secondary-text-color: #ccc;
    --bkg-color: #333;
    --secondary-bkg-color: #555;
    --loader-bkg-color: #333333a6;
    --skeleton-bkg-color: #666;
    --nav-color: rgba(51, 51, 51, 0.801);
    --shadow: #00000091;
    --main-color: #0090f3;
    --card-bkg: #3c3c3c;
}