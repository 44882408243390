.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #333333b8;
    padding: 20px;
    box-sizing: border-box;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal {
        background-color: var(--bkg-color);
        max-width: 600px;
        padding: 25px 20px;
        border-radius: 10px;
        position: relative;
        box-shadow: 0px 3px 39px var(--shadow);
        color: var(--text-color);

        width: 100%;

        h3 {
            margin: 0;
            color: var(--text-color);
            margin-bottom: 15px;
        }

        .close-button {
            position: absolute;
            top: 20px;
            right: 15px;
            cursor: pointer;
            font-size: 25px;
            color: var(--text-color);
        }
    }
}
