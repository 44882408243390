.title{
    margin: 0;
    margin-bottom: 8px;
    color: var(--text-color);
}

.description{
    color: var(--text-color);
    display: block;
    font-size: 13px;
    margin-bottom: 15px;
}