.generic-input-container {
    position: relative;

    select {
        width: 100%;
        padding: 10px;
        border: 0;
        background-color: #f2f2f2;
        color: #333;
        border-radius: 6px;
    }
}

.generic-input {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    background-color: var(--secondary-bkg-color);
    color: var(--text-color);
    border: 0;
    border-radius: 8px;
    outline: none;
    border: 1px solid transparent;
}

.generic-input-label {
    font-family: sans-serif;
    font-size: 12px;
    color: var(--text-color);
}

.generic-input-container {
    margin-bottom: 15px;
}

.errorMsg {
    font-size: 11px;
    position: absolute;
    top: 3px;
    right: 0;
    color: #e93636;
}

.invalid-input {
    border: 1px solid #e93636;
}

.generic-button {
    width: 100%;
    border: 0;
    padding: 21px;
    background-color: var(--main-color);
    border-radius: 14px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: 0.2s;

    &.fitContent{
        width: auto;
        padding: 10px 20px;
    }

    &.fullWidth{
        width: 100%;
    }

    &:disabled {
        background-color: #999;
    }

    &.google-button {
        background-color: #fff;
        color: #666;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;

        img {
            width: 20px;
            margin-right: 10px;
        }
    }
}

.secondary-button {
    width: 100%;
    border: 0;
    padding: 21px;
    background-color: transparent;
    border-radius: 14px;
    color: var(--main-color);
    background-color: #ebf2f8;
    font-weight: bold;
    cursor: pointer;
}

.tertiary-button {
    width: 100%;
    border: 0;
    padding: 21px;
    background-color: transparent;
    border-radius: 14px;
    color: var(--main-color);
    background-color: var(--nav-color);
    font-weight: bold;
    cursor: pointer;

    border: 1px solid var(--bkg-color);
    backdrop-filter: blur(3px);
    color: var(--text-color);
}

.red-button{
    background-color: #ff6060;
}

.shadow {
    box-shadow: 0px 4px 4px #00000011;
}

.loading {
    cursor: not-allowed;
    pointer-events: none;
    background: linear-gradient(90deg, rgba(0,123,255,0.5) 25%, var(--main-color) 50%, rgba(0,123,255,0.5) 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
  }
  
  /* Animación de carga */
  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }

