.container {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    overflow: hidden;
    border: 3px solid #fff;
    box-shadow: 0 2px 7px rgba(51, 51, 51, .14901960784313725);

    &.xsm{
        width: 20px;
        height: 20px;
        border: 2px solid #fff;
    }

    &.sm{
        width: 30px;
        height: 30px;
    }

    &.md{
        width: 40px;
        height: 40px;
    }

    .image {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}