.container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;

    .item{
        padding: 20px;

        h4{
            margin: 0;
            color: var(--text-color);
        }

        span{
            font-size: 12px;
            color: var(--text-color);
        }
    }

    .item:first-of-type{
        border-right: 1px solid var(--secondary-bkg-color);
    }
}